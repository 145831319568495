<template>
  <PivotTableSettingsStyled>
    <div>{{ $tc('column', 2) }}:</div>
    <SelectStyled>
      <template v-for="(attribute, i) in pivotTableColumnsSettings">
        <CheckBox :label="attribute" :key="i" @checkedAttribute="checkedAttribute" class="checkbox" />
      </template>
    </SelectStyled>
    <div>{{ $tc('row', 2) }}:</div>
    <SelectStyled>
      <template v-for="(attribute, i) in pivotTableRowsSettings">
        <CheckBox :label="attribute" :key="i" @checkedAttribute="checkedAttribute" class="checkbox" />
      </template>
    </SelectStyled>
  </PivotTableSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import CheckBox from '@/components/Misc/CheckBox'
import ADDITIONAL_ATTRIBUTES from '@/components/Widgets/PivotTable/pivotTableAdditionalAttributes'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

const PivotTableSettingsStyled = styled('div')``
const SelectStyled = styled('div')`
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: minmax(10px, auto);
  grid-gap: 1em;
  font-size: 12px;
  .checkbox {
    span {
      text-transform: capitalize;
    }
  }
`

export default {
  inject: ['uiSettings'],
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
    dimensionsSelected: {
      type: Array,
    },
  },
  components: {
    PivotTableSettingsStyled,
    CheckBox,
    SelectStyled,
  },
  data() {
    return {
      additionalAttributes: ADDITIONAL_ATTRIBUTES,
      defaultSettings: {
        sourceAssetTalpaIDColumnShown: true,
        sourceGeofenceColumnShown: true,
        materialTypeColumnShown: true,
        targetAssetTalpaIDColumnShown: true,
        targetGeofenceColumnShown: true,
        assetColumnShown: true,
        sourceAssetTalpaIDRowShown: true,
        sourceGeofenceRowShown: true,
        materialTypeRowShown: true,
        targetAssetTalpaIDRowShown: true,
        targetGeofenceRowShown: true,
        assetRowShown: true,
        userSelectedColumnDimensions: [],
        userSelectedRowDimensions: [],
      },
      removeSelectedColumnDimensions: [],
      addSelectedColumnDimensions: [],
      removeSelectedRowDimensions: [],
      addSelectedRowDimensions: [],
    }
  },
  watch: {
    dimensionsSelected: {
      handler(dimensions) {
        const removedColumnDimension = this.widgetSettingsMapped?.userSelectedColumnDimensions?.filter(
          column => !dimensions.some(e => e.id === column.assetDimension.id),
        )
        const removedRowDimension = this.widgetSettingsMapped?.userSelectedRowDimensions?.filter(
          row => !dimensions.some(e => e.id === row.assetDimension.id),
        )
        if (removedColumnDimension.length > 0) {
          removedColumnDimension.map(e => {
            const exists = this.removeSelectedColumnDimensions.find(selected => selected.id === e.id)
            if (!exists) {
              this.removeSelectedColumnDimensions.push(e)
            }
          })
        }

        if (removedRowDimension.length > 0) {
          removedRowDimension.map(row => {
            const exists = this.removeSelectedRowDimensions.find(selected => selected.id === row.id)
            if (!exists) {
              this.removeSelectedColumnDimensions.push(row)
            }
          })
        }
        this.emitSettings()
      },
    },
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    widgetSettingsMapped() {
      if (isEmpty(this.widgetSettings)) {
        return this.defaultSettings
      } else {
        return this.widgetSettings
      }
    },
    pivotTableColumnsSettings() {
      const defaultAttributes = this.additionalAttributes
        .filter(f => !f.isRow)
        .map(attribute => {
          return {
            id: attribute.id,
            name: this.$t(`pivotTable.${attribute.label}`),
            checked: this.widgetSettingsMapped[attribute.id],
            isRow: false,
          }
        })
      const dimensions = this.dimensionsSelected.map(dimension => {
        return {
          id: dimension.id,
          name: dimension?.nameTranslations ? getAssetDimensionNameByLocale(dimension?.nameTranslations, this.locale) : dimension?.name,
          checked:
            this.widgetSettingsMapped?.userSelectedColumnDimensions !== []
              ? this.widgetSettingsMapped?.userSelectedColumnDimensions?.find(e => e.assetDimension.id === dimension.id)
                ? true
                : false
              : false,
          isRow: false,
          isDimension: true,
        }
      })

      const mappedColumnAttributes = defaultAttributes.concat(dimensions)
      return mappedColumnAttributes
    },
    pivotTableRowsSettings() {
      const defaultAttributes = this.additionalAttributes
        .filter(f => f.isRow)
        .map(attribute => {
          return {
            id: attribute.id,
            name: this.$t(`pivotTable.${attribute.label}`),
            checked: this.widgetSettingsMapped[attribute.id],
            isRow: true,
          }
        })

      const dimensions = this.dimensionsSelected.map(dimension => {
        return {
          id: dimension.id,
          name: dimension.nameTranslations ? getAssetDimensionNameByLocale(dimension.nameTranslations, this.locale) : dimension.name,
          checked:
            this.widgetSettingsMapped?.userSelectedRowDimensions !== []
              ? this.widgetSettingsMapped?.userSelectedRowDimensions?.find(row => row.assetDimension.id === dimension.id)
                ? true
                : false
              : false,
          isRow: true,
          isDimension: true,
        }
      })

      const mappedRowAttributes = defaultAttributes.concat(dimensions)
      return mappedRowAttributes
    },
  },
  methods: {
    checkedAttribute(attribute) {
      if (attribute.isDimension) {
        if (!attribute.isRow) {
          const found = this.widgetSettingsMapped?.userSelectedColumnDimensions?.find(
            ({ assetDimension }) => assetDimension.id === attribute.id,
          )
          if (attribute.checked) {
            if (found) {
              this.removeSelectedColumnDimensions = this.removeSelectedColumnDimensions.filter(e => e.assetDimension.id !== attribute.id)
            } else {
              this.addSelectedColumnDimensions.push({
                assetDimension: this.dimensionsSelected.find(e => e.id === attribute.id),
                isEnabled: attribute.checked,
              })
            }
          } else {
            if (found) {
              this.removeSelectedColumnDimensions.push(
                this.widgetSettingsMapped.userSelectedColumnDimensions.filter(
                  ({ assetDimension }) => assetDimension.id === attribute.id,
                )[0],
              )
            } else {
              const foundInAddList = this.addSelectedColumnDimensions.find(({ assetDimension }) => assetDimension.id === attribute.id)
              if (foundInAddList) {
                this.addSelectedColumnDimensions = this.addSelectedColumnDimensions.filter(
                  ({ assetDimension }) => assetDimension.id !== attribute.id,
                )
              } else {
                return
              }
            }
          }
        } else {
          const found = this.widgetSettingsMapped?.userSelectedRowDimensions?.find(
            ({ assetDimension }) => assetDimension.id === attribute.id,
          )
          if (attribute.checked) {
            if (found) {
              this.removeSelectedRowDimensions = this.removeSelectedRowDimensions.filter(row => row.assetDimension.id !== attribute.id)
            } else {
              this.addSelectedRowDimensions.push({
                assetDimension: this.dimensionsSelected.find(dimension => dimension.id === attribute.id),
                isEnabled: attribute.checked,
              })
            }
          } else {
            if (found) {
              this.removeSelectedRowDimensions.push(
                this.widgetSettingsMapped.userSelectedRowDimensions.filter(({ assetDimension }) => assetDimension.id === attribute.id)[0],
              )
            } else {
              const foundInAddList = this.addSelectedRowDimensions.find(({ assetDimension }) => assetDimension.id === attribute.id)
              if (foundInAddList) {
                this.addSelectedRowDimensions = this.addSelectedRowDimensions.filter(
                  ({ assetDimension }) => assetDimension.id !== attribute.id,
                )
              } else {
                return
              }
            }
          }
        }
      } else {
        this.widgetSettingsMapped[attribute.id] = attribute.checked
      }
      this.emitSettings()
    },
    emitSettings: debounce(function () {
      const settings = {
        ...this.widgetSettingsMapped,
        addColumns: this.addSelectedColumnDimensions,
        removeColumns: this.removeSelectedColumnDimensions,
        addRows: this.addSelectedRowDimensions,
        removeRows: this.removeSelectedRowDimensions,
      }
      this.$emit('changeWidgetSettings', { ...settings })
    }, 500),
  },
}
</script>
