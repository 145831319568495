export default [
  { id: 'sourceGeofenceColumnShown', label: 'sourceGeofence', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'targetGeofenceColumnShown', label: 'targetGeofence', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'materialTypeColumnShown', label: 'materialType', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'sourceAssetTalpaIDColumnShown', label: 'sourceAsset', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'targetAssetTalpaIDColumnShown', label: 'targetAsset', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'assetColumnShown', label: 'asset', unit: null, isDateTime: false, isNumber: false, isText: true },
  { id: 'sourceGeofenceRowShown', label: 'sourceGeofence', unit: null, isDateTime: false, isNumber: false, isText: true, isRow: true },
  { id: 'targetGeofenceRowShown', label: 'targetGeofence', unit: null, isDateTime: false, isNumber: false, isText: true, isRow: true },
  { id: 'materialTypeRowShown', label: 'materialType', unit: null, isDateTime: false, isNumber: false, isText: true, isRow: true },
  { id: 'sourceAssetTalpaIDRowShown', label: 'sourceAsset', unit: null, isDateTime: false, isNumber: false, isText: true, isRow: true },
  { id: 'targetAssetTalpaIDRowShown', label: 'targetAsset', unit: null, isDateTime: false, isNumber: false, isText: true, isRow: true },
  { id: 'assetRowShown', label: 'asset', unit: null, isDateTime: false, isNumber: false, isText: true, isRow: true },
]
