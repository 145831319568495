var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PivotTableSettingsStyled', [_c('div', [_vm._v(_vm._s(_vm.$tc('column', 2)) + ":")]), _c('SelectStyled', [_vm._l(_vm.pivotTableColumnsSettings, function (attribute, i) {
    return [_c('CheckBox', {
      key: i,
      staticClass: "checkbox",
      attrs: {
        "label": attribute
      },
      on: {
        "checkedAttribute": _vm.checkedAttribute
      }
    })];
  })], 2), _c('div', [_vm._v(_vm._s(_vm.$tc('row', 2)) + ":")]), _c('SelectStyled', [_vm._l(_vm.pivotTableRowsSettings, function (attribute, i) {
    return [_c('CheckBox', {
      key: i,
      staticClass: "checkbox",
      attrs: {
        "label": attribute
      },
      on: {
        "checkedAttribute": _vm.checkedAttribute
      }
    })];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }