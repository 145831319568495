<template>
  <CheckBoxStyled>
    <label class="container">
      <span> {{ label.name }} </span>
      <input type="checkbox" v-model="checked" :disabled="label.disabled" />
      <span class="checkmark"></span>
    </label>
  </CheckBoxStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { get } from 'lodash'

const CheckBoxStyled = styled('label')`
  .container {
    display: block;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: ${({ theme }) => theme.colors.atomic.textMain};
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    border-radius: 3px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  .container input:disabled ~ .checkmark {
    background-color: ${({ theme }) => theme.colors.atomic.iconBG};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    color: ${({ theme }) => theme.colors.white};
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 4px;
    top: 0px;
    width: 4px;
    height: 10px;
    border: solid ${({ theme }) => theme.colors.white};
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export default {
  props: {
    label: {
      type: Object,
    },
  },
  components: {
    CheckBoxStyled,
  },
  computed: {
    checked: {
      get() {
        return get(this.label, 'checked', false)
      },
      set(value) {
        const selectedLabel = { ...this.label }
        selectedLabel.checked = value
        this.$emit('checkedAttribute', selectedLabel)
      },
    },
  },
}
</script>
